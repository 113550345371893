import React, { useState, useEffect } from "react";
import styles from "./FacilityReportsWrapper.module.css";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import FacilityReportsHeader from "./FacilityReportsHeader";
import Popper from "@mui/material/Popper";
import PopupState, { bindToggle, bindPopper } from "material-ui-popup-state";
import Radio from '@mui/material/Radio';
import CloseIcon from '@mui/icons-material/Close';
import Input from '@material-ui/core/Input';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Divider from '@material-ui/core/Divider';
import { FaSort } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { getSelectedProjectIdAndLocationId, getUserProfile } from "../../../Reducer/selectors/DashboardSelector";
import { getFacilityReport, getFacilityReportDownloadLink, getFacilityReportSuccess, putUpdateFacilityReport, setDownloadReportLink } from "../../../Actions";
import { getDownloadReportLink, getFacilityBPHSelector, getFacilityReportSelector, getReportStatusSelector } from "../../../Reducer/selectors/PatientSelector";
import { getReportsApplyStatus, getReportsLoader } from "../../../Reducer/selectors/UtilSelector";
import { setReportsApplyStatus, setShowReportsLoader } from "../../../Actions/UtilActions";
import { CircularProgress } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Checkbox } from "@mui/material";

export default function FacilityReportsWrapper() {

    const dispatch = useDispatch();

    const projectAndLocation = useSelector(getSelectedProjectIdAndLocationId);
    const userProfile = useSelector(getUserProfile);

    const useStyles = makeStyles((theme) => ({
        tooltip: {
            marginTop: 5,
            font: "12px",
            background: "black",
            fontFamily: "GTWalsheimProRegular",
        },
    }));

    const monthNames = Array.from({ length: 12 }, (_, i) =>
        new Date(0, i).toLocaleString("default", { month: "short" })
    );

    const classes = useStyles();

    const [selectedYear, setSelectedYear] = useState("");
    const [selectedMonth, setSelectedMonth] = useState("");
    const [selectedMonthNum, setSelectedMonthNum] = useState("");
    const [monthFilters, setMonthFilters] = useState(monthNames);

    const facilityBPH = useSelector(getFacilityBPHSelector);

    const [selectedProgram, setSelectedProgram] = useState("");
    const [programFilters, setProgramFilters] = useState(["RPM", "CCM"]);
    const [selectedProvider, setSelectedProvider] = useState("");
    const [providerFilters, setProviderFilters] = useState(facilityBPH);
    const [selectedCPTCode, setSelectedCPTCode] = useState("");
    const [cptCodeFilters, setCPTCodeFilters] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState("Active");
    const [statusFilters, setStatusFilters] = useState(["Active", "Inactive"]);

    const [searched, setSearched] = useState("");

    const [isApplyActive, setIsApplyActive] = useState(false);
    const [isDownloadActive, setIsDownloadActive] = useState(false);

    const downloadReportLink = useSelector(getDownloadReportLink);

    const patientsReportList = useSelector(getFacilityReportSelector);


    const getReportStatus = useSelector(getReportStatusSelector);

    const reportsLoader = useSelector(getReportsLoader);

    const [reportsListSorting, setReportsListSorting] = useState([]);

    const [sortDirection, setSortDirection] = useState(false);

    const applyStatus = useSelector(getReportsApplyStatus);

    const [selectedTab, setSelectedTab] = useState(0);

    const [billingInfo, setBillingInfo] = useState([]);

    const [unbillingInfo, setUnbillingInfo] = useState([]);

    const [inBillingMode, setInBillingMode] = useState(false);
    const [maxBillEntries, setMaxBillEntries] = useState(0);
    const [maxUnbillEntries, setMaxUnbillEntries] = useState(0);

    useEffect(() => {
        const { maxBillCount, maxUnbillCount } = reportsListSorting.reduce(
            (acc, report) => {
                if (report.billedCodes?.length > 0) {
                    acc.maxUnbillCount += 1;
                }
                if (report.unbilledCodes?.length > 0) {
                    acc.maxBillCount += 1;
                }
                return acc;
            },
            { maxBillCount: 0, maxUnbillCount: 0 }
        );
    
        setMaxBillEntries(maxBillCount);
        setMaxUnbillEntries(maxUnbillCount);
    }, [reportsListSorting]);    

    useEffect(() => {
        const updatedFilters = new Set(
            patientsReportList.flatMap((report) => [
                ...(report.billedCodes || []), 
                ...(report.unbilledCodes || [])
            ])
        );
    
        setCPTCodeFilters(Array.from(updatedFilters));
    }, [patientsReportList]);

    useEffect(() => {

        const currentYear = new Date().getFullYear()
        const currentMonth = new Date().getMonth()

        if (parseInt(selectedYear) === parseInt(currentYear)) {

            // Change filters to show only till current month
            const newMonthFilter = monthFilters.slice(0, currentMonth+1)
            setMonthFilters(newMonthFilter);

            // Handle month selection -> 
                // If monthSelected > currMonth -> reset
                // If monthSelected < currMonth -> leave as is
            if (selectedMonthNum > currentMonth) {
                setSelectedMonthNum('');
                setSelectedMonth('');
            }
        } else {
            setMonthFilters(monthNames);
        }

    }, [selectedYear])

    function addAllBillingInfo() {
        let newBillingInfo = [];
        for (const i in reportsListSorting) {
            if (reportsListSorting[i].unbilledCodes?.length > 0) {
                newBillingInfo.push(reportsListSorting[i]);
            }
        }
        setBillingInfo(newBillingInfo);
    };

    const handleTabChange = (event, newValue) => {
        setSelectedProgram("");
        setSelectedCPTCode("");
        setSearched("");
        setBillingInfo([]);
        setUnbillingInfo([]);
        setInBillingMode(false);
        setSelectedTab(newValue);
    };

    useEffect(() => {
        dispatch(setReportsApplyStatus(false));
    }, []);

    useEffect(() => {
        setProviderFilters(facilityBPH);
    }, [facilityBPH]);

    useEffect(() => {
        let filterdReportsList = applySelectedFilters(patientsReportList);
        setReportsListSorting(filterdReportsList);
    }, [patientsReportList, selectedProgram, selectedStatus, selectedCPTCode, searched]);

    const checkForCPTCode = (report, selectedCPTCode) => {
        if (selectedTab === 1) 
            return report.billedCodes && report.billedCodes.includes(selectedCPTCode);
        
        if (selectedTab === 0) 
        return report.unbilledCodes && report.unbilledCodes.includes(selectedCPTCode);
    };

    const checkForStatus = (repStatus, status) => {

        if (status.toLowerCase() === "inactive" && repStatus?.toLowerCase() === "inactive") {
            return true;
        }
        if (status.toLowerCase() === "active" && repStatus?.toLowerCase() !== "inactive") {
            return true;
        }

        return false;
    };

    const applySelectedFilters = (reportsList) => {
        let filteredList = reportsList.filter((report) => {
            let matches = true;
            if (selectedProgram !== "") {
                matches = matches && (report.program === selectedProgram);
            }
            if (selectedStatus !== "") {
                matches = matches && checkForStatus(report.status, selectedStatus);
            }
            if (selectedCPTCode !== "") {
                matches = matches && checkForCPTCode(report, selectedCPTCode);
            }
            if (searched !== "") {
                matches = matches && (
                    (report.fullName && report.fullName.toString().toLowerCase().includes(searched.toLowerCase())) ||
                    (report.medicareId && report.medicareId.toString().toLowerCase().includes(searched.toLowerCase())) ||
                    (report.dxCode && report.dxCode.toString().toLowerCase().includes(searched.toLowerCase()))
                );
            }
            return matches;
        });

        return filteredList;
    };

    const clearYearFilter = () => {
        setSelectedYear("");
    };

    const clearMonthFilter = () => {
        setSelectedMonthNum("");
        setSelectedMonth("");
    };

    const clearProgramFilter = () => {
        setSelectedProgram("");
    };

    const clearStatusFilter = () => {
        setSelectedStatus("");
    };

    const clearCPTCodeFilter = () => {
        setSelectedCPTCode("");
    };

    const addToYearFilter = (event, popupState) => {
        event.preventDefault();
        setSelectedYear(event.target.name);
        popupState.close();
    };

    const addToMonthFilter = (event, popupState) => {
        event.preventDefault();
        const monthIndex = monthNames.indexOf(event.target.name); // Get index of the month
        if (monthIndex !== -1) {
            const monthNum = String(monthIndex + 1).padStart(2, "0"); // Convert index to 2-digit month
            setSelectedMonthNum(monthNum);
            setSelectedMonth(event.target.name);
        } else {
            setSelectedMonthNum("");
            setSelectedMonth("");
        }
        popupState.close();
    };

    const addToStatusFilter = (event, popupState) => {
        event.preventDefault();
        setSelectedStatus(event.target.name);
        popupState.close();
    };

    const addToProgramFilter = (event, popupState) => {
        event.preventDefault();
        setSelectedProgram(event.target.name);
        popupState.close();
    };

    const addToCPTCodeFilter = (event, popupState) => {
        event.preventDefault();
        setSelectedCPTCode(event.target.name);
        popupState.close();
    };

    const RenderYearFilters = (props) => {
        return (
            <FormGroup>
                {props.filterList && props.filterList.map(filter =>
                    <FormControlLabel className={selectedYear === filter.toString() ? styles.filtersDivTextSelected : styles.filtersDivText} control={<Radio size="small" sx={{ color: "#AA346F", fontSize: "14px !important", '&.Mui-checked': { color: "#AA346F" } }} name={filter.toString()} checked={selectedYear === filter.toString()} onClick={(event) => addToYearFilter(event, props.popupState)} />} label={<span style={{ fontSize: '14px', fontFamily: "GTWalsheimProRegular" }}>{filter.toString()}</span>} />)}
            </FormGroup>
        );
    };

    const RenderMonthFilters = (props) => {
        return (
            <FormGroup>
                {props.filterList && props.filterList.map(filter =>
                    <FormControlLabel className={selectedMonth === filter.toString() ? styles.filtersDivTextSelected : styles.filtersDivText} control={<Radio size="small" sx={{ color: "#AA346F", fontSize: "14px !important", '&.Mui-checked': { color: "#AA346F" } }} name={filter.toString()} checked={selectedMonth === filter.toString()} onClick={(event) => addToMonthFilter(event, props.popupState)} />} label={<span style={{ fontSize: '14px', fontFamily: "GTWalsheimProRegular" }}>{filter.toString()}</span>} />)}
            </FormGroup>
        );
    };

    const RenderProgramFilters = (props) => {
        return (
            <FormGroup>
                {props.filterList && props.filterList.map(filter =>
                    <FormControlLabel className={selectedProgram === filter.toString() ? styles.filtersDivTextSelected : styles.filtersDivText} control={<Radio size="small" sx={{ color: "#AA346F", fontSize: "14px !important", '&.Mui-checked': { color: "#AA346F" } }} name={filter.toString()} checked={selectedProgram === filter.toString()} onClick={(event) => addToProgramFilter(event, props.popupState)} />} label={<span style={{ fontSize: '14px', fontFamily: "GTWalsheimProRegular" }}>{filter.toString()}</span>} />)}
            </FormGroup>
        );
    };

    const RenderStatusFilters = (props) => {
        return (
            <FormGroup>
                {props.filterList && props.filterList.map(filter =>
                    <FormControlLabel className={selectedStatus === filter.toString() ? styles.filtersDivTextSelected : styles.filtersDivText} control={<Radio size="small" sx={{ color: "#AA346F", fontSize: "14px !important", '&.Mui-checked': { color: "#AA346F" } }} name={filter.toString()} checked={selectedStatus === filter.toString()} onClick={(event) => addToStatusFilter(event, props.popupState)} />} label={<span style={{ fontSize: '14px', fontFamily: "GTWalsheimProRegular" }}>{filter.toString()}</span>} />)}
            </FormGroup>
        );
    };

    const RenderCPTCodeFilters = (props) => {
        return (
            <FormGroup>
                {props.filterList && props.filterList.map(filter =>
                    <FormControlLabel className={selectedCPTCode === filter.toString() ? styles.filtersDivTextSelected : styles.filtersDivText} control={<Radio size="small" sx={{ color: "#AA346F", fontSize: "14px !important", '&.Mui-checked': { color: "#AA346F" } }} name={filter.toString()} checked={selectedCPTCode === filter.toString()} onClick={(event) => addToCPTCodeFilter(event, props.popupState)} />} label={<span style={{ fontSize: '14px', fontFamily: "GTWalsheimProRegular" }}>{filter.toString()}</span>} />)}
            </FormGroup>
        );
    };

    function generateLast5Years() {
        const currentYear = new Date().getFullYear();
        const years = [];

        for (let i = 0; i < 5; i++) {
            years.push((currentYear - i).toString());
        }

        return years;
    }

    const YearFilterPanel = (props) => {
        return (
            <div className={styles.wrapper}>
                <RenderYearFilters filterList={generateLast5Years()} popupState={props.popupState}></RenderYearFilters>
            </div>
        );
    };

    const MonthFilterPanel = (props) => {
        return (
            <div className={styles.wrapper}>
                <RenderMonthFilters filterList={monthFilters} popupState={props.popupState}></RenderMonthFilters>
            </div>
        );
    };

    const ProgramFilterPanel = (props) => {
        return (
            <div className={styles.wrapper}>
                <RenderProgramFilters filterList={programFilters} popupState={props.popupState}></RenderProgramFilters>
            </div>
        );
    };

    const StatusFilterPanel = (props) => {
        return (
            <div className={styles.wrapper}>
                <RenderStatusFilters filterList={statusFilters} popupState={props.popupState}></RenderStatusFilters>
            </div>
        );
    };

    const CPTCodeFilterPanel = (props) => {
        return (
            <div className={styles.wrapper}>
                <RenderCPTCodeFilters filterList={cptCodeFilters} popupState={props.popupState}></RenderCPTCodeFilters>
            </div>
        );
    };

    const requestSearch = (event) => {
        event.preventDefault();
        setSearched(event.target.value);
    };

    const resetSearch = () => {
        setSearched("");
        const patientsReportListCopy = patientsReportList.map((x) => x);
        setReportsListSorting(patientsReportListCopy);
        //setPCCFilters(patientListCopy);
    };

    const handleStringSorting = (key) => {
        setSortDirection((prevSortDirection) => !prevSortDirection);
        let direction = sortDirection;
        let sortableItem = [...reportsListSorting];
        sortableItem.sort((a, b) => {
            if (direction === false) {
                return a[key]?.localeCompare(b[key]);
            } else {
                return b[key]?.localeCompare(a[key]);
            }
        });
        return setReportsListSorting(sortableItem);
    };

    const handleNumberSorting = (key) => {
        setSortDirection((prevSortDirection) => !prevSortDirection);
        let direction = sortDirection;
        let sortableItem = [...reportsListSorting];
        sortableItem.sort((a, b) => {
            if (direction === false) {
                return a[key] - b[key];
            } else {
                return b[key] - a[key];
            }
        });
        return setReportsListSorting(sortableItem);
    };

    useEffect(() => {
        if (selectedYear !== "" && selectedMonthNum !== "") {
            setIsApplyActive(true);
        } else {
            dispatch(getFacilityReportSuccess([]));
            dispatch(setReportsApplyStatus(false));
            setIsApplyActive(false);
        }
    }, [selectedYear, selectedMonthNum]);

    useEffect(() => {
        if (downloadReportLink !== '') {
            window.open(downloadReportLink);
            dispatch(setDownloadReportLink(""));
        }
        setIsDownloadActive(false);
    }, [downloadReportLink]);

    const fetchReport = (event) => {
        event?.preventDefault();
        if (isApplyActive) {
            const payload = {
                userId: userProfile.id,
                year: selectedYear,
                month: selectedMonthNum,
                projectId: projectAndLocation.selectedProjectId,
                locationId: projectAndLocation.selectedLocationId,
            };
            dispatch(setShowReportsLoader(true));
            dispatch(getFacilityReportSuccess([]));
            dispatch(setDownloadReportLink(""));
            dispatch(getFacilityReport(payload));
            dispatch(setReportsApplyStatus(true));
            setSelectedProgram("");
            setSelectedCPTCode("");
            setSelectedStatus("Active");
            setSearched("");
            setSelectedTab(0);
            setBillingInfo([]);
            setUnbillingInfo([]);
            setInBillingMode(false);
        }
    };

    const downloadReport = (event) => {
        event.preventDefault();
        dispatch(setDownloadReportLink(""));
        setIsDownloadActive(true);
        const payload = {
            userId: userProfile.id,
            year: selectedYear,
            month: selectedMonthNum,
            projectId: projectAndLocation.selectedProjectId,
            locationId: projectAndLocation.selectedLocationId,
            allBillingInfo: patientsReportList,
        };
        dispatch(getFacilityReportDownloadLink(payload));
    };


    const CPTCodesUnbilled = (props) => {
        switch (props.program) {
            case "RPM":
                return (
                    <>
                        <div className={styles.rpmCodesContainer}>
                            {props.unbilledCodes.includes("99453") ? <div className={styles.rpmFulFilled}>99453</div> : <></>}
                            {props.unbilledCodes.includes("99454") ? <div className={styles.rpmFulFilled}>99454</div> : <></>}
                            {props.unbilledCodes.includes("99457") ? <div className={styles.rpmFulFilled}>99457</div> : <></>}
                            {props.unbilledCodes.includes("99458") ? <div className={styles.rpmFulFilled}>99458</div> : <></>}
                        </div>
                    </>
                );

            case "CCM":
                return (
                    <>
                        <div className={styles.ccmCodesContainer}>
                            {props.unbilledCodes.includes("99439") ? <div className={styles.ccmFulFilled}>99439</div> : <></>}
                            {props.unbilledCodes.includes("99490") ? <div className={styles.ccmFulFilled}>99490</div> : <></>}
                        </div>
                    </>
                );

            default:
                return (
                    <>
                    </>
                );
        }
    };


    const CPTCodesBilled = (props) => {
        switch (props.program) {
            case "RPM":
                return (
                    <>
                        <div className={styles.rpmCodesContainer}>
                            {props.billedCodes.includes("99453") ? <div className={styles.rpmFulFilled}>99453</div> : <></>}
                            {props.billedCodes.includes("99454") ? <div className={styles.rpmFulFilled}>99454</div> : <></>}
                            {props.billedCodes.includes("99457") ? <div className={styles.rpmFulFilled}>99457</div> : <></>}
                            {props.billedCodes.includes("99458") ? <div className={styles.rpmFulFilled}>99458</div> : <></>}
                        </div>
                    </>
                );

            case "CCM":
                return (
                    <>
                        <div className={styles.ccmCodesContainer}>
                            {props.billedCodes.includes("99439") ? <div className={styles.ccmFulFilled}>99439</div> : <></>}
                            {props.billedCodes.includes("99490") ? <div className={styles.ccmFulFilled}>99490</div> : <></>}
                        </div>
                    </>
                );

            default:
                return (
                    <>
                    </>
                );
        }
    };

    const padZero = (n) => {
        return (n < 10) ? ("0" + n) : n;
    };

    const handleAddBillingInfo = (report) => {
        if (billingInfo.includes(report)) {
            let copyBillingInfo = billingInfo;
            var index = billingInfo.indexOf(report);
            if (index > -1) {
                copyBillingInfo.splice(index, 1);
            }
            setBillingInfo([...copyBillingInfo]);
        } else {
            setBillingInfo([...billingInfo, report]);
        }
    };

    const handleAddUnbillingInfo = (report) => {
        if (unbillingInfo.includes(report)) {
            let copyUnbillingInfo = unbillingInfo;
            var index = unbillingInfo.indexOf(report);
            if (index > -1) {
                copyUnbillingInfo.splice(index, 1);
            }
            setUnbillingInfo([...copyUnbillingInfo]);
        } else {
            setUnbillingInfo([...unbillingInfo, report]);
        }
    };

    const sleep = ms => new Promise(r => setTimeout(r, ms));

    const handleUpdateBillingInfo = async (event) => {
        event.preventDefault();
        dispatch(setShowReportsLoader(true));
        let billThese = billingInfo;
        let unbillThese = unbillingInfo;

        const payload = {
            billThese: billThese,
            unbillThese: unbillThese,
        };

        dispatch(putUpdateFacilityReport(payload));
        setInBillingMode(false);
        await sleep(4000);
        fetchReport();
    };

    const reportsTableBody = reportsListSorting.map((report) => {
        if (selectedTab === 0) {
            if (report.unbilledCodes?.length > 0) {
                return (
                    <div>
                        <table className={styles.responsive1}>
                            <tbody>
                                <tr>
                                    <td className={styles.tableCellContentsFullName}><div className={styles.tableCellContainer}>{report.fullName}</div></td>
                                    <td className={styles.tableCellContentsDOB}><div className={styles.tableCellContainer}>{report.dob}</div></td>
                                    <td className={styles.tableCellContentsMId}><div className={styles.tableCellContainer}>{report.medicareId}</div></td>
                                    <td className={styles.tableCellContentsProgram}><div className={report.program === "RPM" ? styles.tableCellContainerProgramRPM : styles.tableCellContainerProgramCCM}>{report.program}</div></td>
                                    <td className={styles.tableCellContentsDXCode}><div className={styles.tableCellContainer}>{report.dxCode.length > 20 ? report.dxCode.substring(0, 16) + '...' : report.dxCode}</div></td>
                                    <td className={styles.tableCellContentsCPTCode}><div className={styles.tableCellContainer}>{<CPTCodesUnbilled program={report.program} billedCodes={report.billedCodes} unbilledCodes={report.unbilledCodes} />}</div></td>
                                    <td className={styles.tableCellContentsUsage}><div className={parseInt(report.usage, 10) >= 16 ? styles.tableCellContainerUsageFulFilled : styles.tableCellContainerUsageUnfulFilled}>{report.usage + "/16"}</div></td>
                                    <td className={styles.tableCellContentsTimeSpent}><div className={styles.tableCellContainer}>{padZero(Math.trunc(parseInt(report.totalTimeInSeconds, 10) / 60)) + ":" + padZero(parseInt(report.totalTimeInSeconds, 10) % 60)}</div></td>
                                    <td className={styles.tableCellContentsSelected}><div className={styles.tableCellContainerCheckbox}>{<Checkbox checked={billingInfo.includes(report)} onClick={() => handleAddBillingInfo(report)} disabled={!inBillingMode} size='small' sx={{ '&.Mui-checked': { color: "#4E253A" }, }} />}</div></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>);
            } else {
                return (<></>);
            }
        } else {
            if (report.billedCodes?.length > 0) {
                return (
                    <div>
                        <table className={styles.responsive1}>
                            <tbody>
                                <tr>
                                    <td className={styles.tableCellContentsFullName}><div className={styles.tableCellContainer}>{report.fullName}</div></td>
                                    <td className={styles.tableCellContentsDOB}><div className={styles.tableCellContainer}>{report.dob}</div></td>
                                    <td className={styles.tableCellContentsMId}><div className={styles.tableCellContainer}>{report.medicareId}</div></td>
                                    <td className={styles.tableCellContentsProgram}><div className={report.program === "RPM" ? styles.tableCellContainerProgramRPM : styles.tableCellContainerProgramCCM}>{report.program}</div></td>
                                    <td className={styles.tableCellContentsDXCode}><div className={styles.tableCellContainer}>{report.dxCode}</div></td>
                                    <td className={styles.tableCellContentsCPTCode}><div className={styles.tableCellContainer}>{<CPTCodesBilled program={report.program} billedCodes={report.billedCodes} unbilledCodes={report.unbilledCodes} />}</div></td>
                                    <td className={styles.tableCellContentsUsage}><div className={parseInt(report.usage, 10) >= 16 ? styles.tableCellContainerUsageFulFilled : styles.tableCellContainerUsageUnfulFilled}>{report.usage + "/16"}</div></td>
                                    <td className={styles.tableCellContentsTimeSpent}><div className={styles.tableCellContainer}>{padZero(Math.trunc(parseInt(report.totalTimeInSeconds, 10) / 60)) + ":" + padZero(parseInt(report.totalTimeInSeconds, 10) % 60)}</div></td>
                                    <td className={styles.tableCellContentsSelected}><div className={styles.tableCellContainerCheckbox}>{<Checkbox checked={unbillingInfo.includes(report)} onClick={() => handleAddUnbillingInfo(report)} disabled={!inBillingMode} size='small' sx={{ '&.Mui-checked': { color: "#4E253A" }, }} />}</div></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>);
            } else {
                return (<></>)
            }

        }

    });

    const AntTab = withStyles((theme) => ({
        root: {
            textTransform: "none",
            color: "#4E253A",
            background: `#f7e3e6`,
            minWidth: "50%",
            height: "50%",
            borderRight: "0px solid #cccaca",
            fontSize: `14px`,
            fontFamily: ["GTWalsheimProRegular"].join(","),
            fontWeight: theme.typography.fontWeightBold,
            minHeight: '36px',
            height: '36px',
            "&:last-child": {
                borderRight: `none`,
            },
            "&:hover": {
                color: "#4E253A",
                //opacity: 1,
            },
            "&:focus": {
                color: "#4E253A",
            },
            //selected tab styles
            "&$selected": {
                color: "#4E253A",
                backgroundColor: "#FFFFFF",
                fontWeight: theme.typography.fontWeightBold,
            },
        },
        selected: {},
    }))((props) => <Tab disableRipple {...props} />);

    const AntTabs = withStyles({
        root: {
            //boxShadow: "0px 1px 5px 0px #cccaca",
            backgroundColor: `#FFCAE5`,
            width: "100%",
            minHeight: '36px',
            height: '36px',
            color: '#4E253A'
        },
        indicator: {
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            backgroundColor: "transparent",
            "& > span": {
                maxWidth: "100%",
                width: "100%",
                backgroundColor: "#cccaca",
            },
        },
    })((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);


    function handleChangeBillingMode() {
        setInBillingMode(!inBillingMode);
        if (!inBillingMode && selectedTab === 0) {
            addAllBillingInfo();
        }
    };

    function handleCancelBillingMode() {
        setInBillingMode(false);
        setBillingInfo([]);
        setUnbillingInfo([]);
    };

    return (
        <>
            <FacilityReportsHeader></FacilityReportsHeader>
            <div className={styles.containerDiv}>
                <div className={styles.datePickerDiv}>
                    <div className={styles.datePickerIconDiv}>
                        <Tooltip title="Choose another date range" classes={{ tooltip: classes.tooltip }}>
                            <CalendarMonthIcon></CalendarMonthIcon>
                        </Tooltip>
                    </div>

                    <PopupState variant="popper" popupId="year-popup-popper" placement="bottom-start">
                        {(popupState) => (
                            <div className={styles.filterDiv}>
                                <div className={styles.filterToggle} {...bindToggle(popupState)}>
                                    <Radio size="small" sx={{ color: "#AA346F", '&.Mui-checked': { color: "#AA346F" } }} checked={true}></Radio>
                                    <div className={styles.filterDivLabel}>{selectedYear !== "" ? selectedYear : 'Year'}</div>
                                    {selectedYear === "" && (
                                        <ExpandMoreIcon style={{ alignSelf: "center", marginRight: "5px", marginLeft: "auto", color: "#4E253A", height: "20px", width: "20px" }}></ExpandMoreIcon>
                                    )}
                                {selectedYear !== "" && (
                                    <CloseIcon onClick={clearYearFilter} style={{ alignSelf: "center", marginRight: "5px", marginLeft: "auto", color: "#4E253A", height: "16px", width: "16px" }}></CloseIcon>
                                )}
                                </div>
                                <Popper {...bindPopper(popupState)} transition>
                                    {({ TransitionProps }) => (
                                        <Fade {...TransitionProps} timeout={350}>
                                            <Paper className={styles.filterBackdrop}>
                                                <YearFilterPanel popupState={popupState} />
                                            </Paper>
                                        </Fade>
                                    )}
                                </Popper>
                            </div>
                        )}
                    </PopupState>

                    <PopupState variant="popper" popupId="month-popup-popper" placement="bottom-start">
                        {(popupState) => (
                            <div className={styles.filterDiv}>
                                <div className={styles.filterToggle} {...bindToggle(popupState)}>
                                    <Radio size="small" sx={{ color: "#AA346F", '&.Mui-checked': { color: "#AA346F" } }} checked={true}></Radio>
                                    <div className={styles.filterDivLabel}>{selectedMonth !== "" ? selectedMonth : 'Month'}</div>
                                    {selectedMonth === "" && (
                                        <ExpandMoreIcon style={{ alignSelf: "center", marginRight: "5px", marginLeft: "auto", color: "#4E253A", height: "20px", width: "20px" }}></ExpandMoreIcon>
                                    )}
                                {selectedMonth !== "" && (
                                    <CloseIcon onClick={clearMonthFilter} style={{ alignSelf: "center", marginRight: "5px", marginLeft: "auto", color: "#4E253A", height: "16px", width: "16px" }}></CloseIcon>
                                )}
                                </div>
                                <Popper {...bindPopper(popupState)} transition>
                                    {({ TransitionProps }) => (
                                        <Fade {...TransitionProps} timeout={350}>
                                            <Paper className={styles.filterBackdrop}>
                                                <MonthFilterPanel popupState={popupState} />
                                            </Paper>
                                        </Fade>
                                    )}
                                </Popper>
                            </div>
                        )}
                    </PopupState>

                    <Divider orientation="vertical" flexItem style={{ marginTop: '8px', marginBottom: '8px' }} />

                    <div className={styles.buttonContainer}>
                        <div
                            className={isApplyActive ? styles.applyButton : styles.applyButtonDisabled}
                            onClick={isApplyActive ? (e) => fetchReport(e) : null}
                        >
                            Apply
                        </div>

                        <div className={(patientsReportList.length > 0 && !reportsLoader) ? isDownloadActive ? styles.downloadingButton : styles.downloadButton : styles.downloadButtonDisabled}
                        onClick={patientsReportList.length > 0 ? (e) => downloadReport(e) : null}>
                            <span>{isDownloadActive? 'Downloading...': 'Download'}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles.reportDiv}>
                <div className={styles.reportDivHeader}>
                    Monthly Report
                </div>
                <div className={styles.reportDivBody}>
                    <AntTabs value={selectedTab} onChange={handleTabChange} aria-label="ant example" variant="fullWidth">
                        <AntTab label="Unbilled" />
                        <AntTab label="Billed" />
                    </AntTabs>
                    <div className={styles.topDiv}>
                        
                            <Input
                                className={styles.searchBar}
                                type="text"
                                placeholder="Search"
                                value={searched}
                                onChange={requestSearch}
                                endAdornment={
                                    <InputAdornment position="end">
                                        {
                                            <IconButton className={styles.searchIcon}>
                                                {searched === "" ? <></> : <CloseIcon style={{ color: "#4E253A" }} onClick={resetSearch}></CloseIcon>}
                                            </IconButton>
                                        }
                                    </InputAdornment>
                                }
                                startAdornment={
                                    <InputAdornment position="start">
                                        <IconButton className={styles.searchIcon}>
                                            <SearchIcon style={{ color: "#4E253A" }}></SearchIcon>
                                        </IconButton>
                                    </InputAdornment>
                                }
                                disableUnderline={true}
                                style={{
                                    pointerEvents: (patientsReportList?.length || 0) === 0 ? 'none' : 'auto',
                                    fontFamily: "GTWalsheimProRegular"
                                }}
                            />

                        <PopupState variant="popper" popupId="status-popup-popper" placement="bottom-start">
                            {(popupState) => (
                                <div className={styles.filterDiv} style={{
                                        cursor: (patientsReportList?.length || 0) === 0 ? 'not-allowed' : 'pointer',
                                        opacity: (patientsReportList?.length || 0) === 0 ? 0.6 : 1
                                    }}>
                                    <div className={styles.filterToggle} {...bindToggle(popupState)} style={{
                                        pointerEvents: (patientsReportList?.length || 0) === 0 ? 'none' : 'auto',
                                    }}>
                                        <Radio size="small" sx={{ color: "#AA346F", '&.Mui-checked': { color: "#AA346F" } }} checked={true}></Radio>
                                        <div className={styles.filterDivLabel}>{selectedStatus !== "" ? selectedStatus : 'Status'}</div>
                                        {selectedStatus === "" && (
                                            <ExpandMoreIcon style={{ alignSelf: "center", marginRight: "5px", marginLeft: "auto", color: "#4E253A", height: "20px", width: "20px" }}></ExpandMoreIcon>
                                        )}
                                    {selectedStatus !== "" && (
                                        <CloseIcon onClick={clearStatusFilter} style={{ alignSelf: "center", marginRight: "5px", marginLeft: "auto", color: "#4E253A", height: "16px", width: "16px" }}></CloseIcon>
                                    )}
                                    </div>
                                    <Popper {...bindPopper(popupState)} transition>
                                        {({ TransitionProps }) => (
                                            <Fade {...TransitionProps} timeout={350}>
                                                <Paper className={styles.filterBackdrop}>
                                                    <StatusFilterPanel popupState={popupState} />
                                                </Paper>
                                            </Fade>
                                        )}
                                    </Popper>
                                </div>
                            )}
                        </PopupState>

                        <PopupState variant="popper" popupId="program-popup-popper" placement="bottom-start">
                            {(popupState) => (
                                <div className={styles.filterDiv} style={{
                                        cursor: (patientsReportList?.length || 0) === 0 ? 'not-allowed' : 'pointer',
                                        opacity: (patientsReportList?.length || 0) === 0 ? 0.6 : 1
                                    }}>
                                    <div className={styles.filterToggle} {...bindToggle(popupState)} style={{
                                        pointerEvents: (patientsReportList?.length || 0) === 0 ? 'none' : 'auto',
                                    }}>
                                        <Radio size="small" sx={{ color: "#AA346F", '&.Mui-checked': { color: "#AA346F" } }} checked={true}></Radio>
                                        <div className={styles.filterDivLabel}>{selectedProgram !== "" ? selectedProgram : 'Program'}</div>
                                        {selectedProgram === "" && (
                                            <ExpandMoreIcon style={{ alignSelf: "center", marginRight: "5px", marginLeft: "auto", color: "#4E253A", height: "20px", width: "20px" }}></ExpandMoreIcon>
                                        )}
                                    {selectedProgram !== "" && (
                                        <CloseIcon onClick={clearProgramFilter} style={{ alignSelf: "center", marginRight: "5px", marginLeft: "auto", color: "#4E253A", height: "16px", width: "16px" }}></CloseIcon>
                                    )}
                                    </div>
                                    <Popper {...bindPopper(popupState)} transition>
                                        {({ TransitionProps }) => (
                                            <Fade {...TransitionProps} timeout={350}>
                                                <Paper className={styles.filterBackdrop}>
                                                    <ProgramFilterPanel popupState={popupState} />
                                                </Paper>
                                            </Fade>
                                        )}
                                    </Popper>
                                </div>
                            )}
                        </PopupState>


                        <PopupState variant="popper" popupId="cptcode-popup-popper" placement="bottom-start">
                            {(popupState) => (
                                <div className={styles.filterDiv} style={{
                                        cursor: (patientsReportList?.length || 0) === 0 ? 'not-allowed' : 'pointer',
                                        opacity: (patientsReportList?.length || 0) === 0 ? 0.6 : 1
                                    }}>
                                    <div className={styles.filterToggle} {...bindToggle(popupState)} style={{
                                        pointerEvents: (patientsReportList?.length || 0) === 0 ? 'none' : 'auto',
                                    }}>
                                        <Radio size="small" sx={{ color: "#AA346F", '&.Mui-checked': { color: "#AA346F" } }} checked={true}></Radio>
                                        <div className={styles.filterDivLabel}>{selectedCPTCode !== "" ? selectedCPTCode : 'Code'}</div>
                                        {selectedCPTCode === "" && (
                                            <ExpandMoreIcon style={{ alignSelf: "center", marginRight: "5px", marginLeft: "5px", color: "#4E253A", height: "20px", width: "20px" }}></ExpandMoreIcon>
                                        )}
                                    {selectedCPTCode !== "" && (
                                        <CloseIcon onClick={clearCPTCodeFilter} style={{ alignSelf: "center", marginRight: "5px", marginLeft: "5px", color: "#4E253A", height: "16px", width: "16px" }}></CloseIcon>
                                    )}
                                    </div>
                                    <Popper {...bindPopper(popupState)} transition>
                                        {({ TransitionProps }) => (
                                            <Fade {...TransitionProps} timeout={350}>
                                                <Paper className={styles.filterBackdrop}>
                                                    <CPTCodeFilterPanel popupState={popupState} />
                                                </Paper>
                                            </Fade>
                                        )}
                                    </Popper>
                                </div>
                            )}
                        </PopupState>

                        <div className={(inBillingMode || reportsListSorting.length <= 0) ? styles.billingButtonDisabled : styles.billingButton} onClick={() => (inBillingMode || reportsListSorting.length <= 0) ? {} : handleChangeBillingMode()}>
                            {selectedTab === 0 ? 'Bill' : 'Unbill'}
                        </div>
                    </div>
                    <div className={styles.tableDiv}>
                        <table className={styles.responsive}>
                            <thead>
                                <tr>
                                    <th className={styles.fullNameHeader} onClick={() => handleStringSorting("fullName")}>
                                        <div className={styles.headingContainer1}>
                                            Full Name
                                            <FaSort className={styles.iconsStyleSort} />
                                        </div>
                                    </th>
                                    <th className={styles.dobHeader} onClick={() => handleStringSorting("dob")}>
                                        <div className={styles.headingContainer1}>
                                            DOB
                                            <FaSort className={styles.iconsStyleSort} />
                                        </div>
                                    </th>
                                    <th className={styles.medicareIDHeader} onClick={() => handleStringSorting("medicareId")}>
                                        <div className={styles.headingContainer1}>
                                            Medicare ID
                                            <FaSort className={styles.iconsStyleSort} />
                                        </div>
                                    </th>
                                    <th className={styles.programHeader} onClick={() => handleStringSorting("program")}>
                                        <div className={styles.headingContainer1}>
                                            Program
                                            <FaSort className={styles.iconsStyleSort} />
                                        </div>
                                    </th>
                                    <th className={styles.dxCodeHeader}>
                                        <div className={styles.headingContainer1}>
                                            Dx Code
                                        </div>
                                    </th>
                                    <th className={styles.cptCodeHeader}>
                                        <div className={styles.headingContainer1}>
                                            CPT Code
                                        </div>
                                    </th>
                                    <th className={styles.usageHeader} onClick={() => handleNumberSorting("usage")}>
                                        <div className={styles.headingContainer1}>
                                            Usage
                                            <FaSort className={styles.iconsStyleSort} />
                                        </div>
                                    </th>
                                    <th className={styles.timeSpentHeader} onClick={() => handleNumberSorting("totalTimeInSeconds")}>
                                        <div className={styles.headingContainer1}>
                                            Time Spent
                                            <FaSort className={styles.iconsStyleSort} />
                                        </div>
                                    </th>
                                    <th className={styles.selectedHeader}>
                                        <div className={styles.headingContainer1}>
                                            Selected
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                        </table>
                        <div className={styles.reportsTableBody} style={{ maxHeight: '60vh', overflowY: 'scroll', width: '100%' }}>
                            {reportsLoader ?
                                (<div className={styles.circularProgress}><CircularProgress thickness={5.0} /></div>) :
                                applyStatus ?
                                reportsListSorting.length > 0 ? getReportStatus ? 
                                selectedTab === 0 ? 
                                    (maxBillEntries === 0 ? 
                                        (<div className={styles.circularProgress}>{"No patients available to be billed."}</div>) : reportsTableBody) :
                                    (maxUnbillEntries === 0 ? 
                                        (<div className={styles.circularProgress}>{"No patients available that have been billed."}</div>) : reportsTableBody) :
                                (<div className={styles.circularProgress}>{"We are unable to generate reports at this moment. Please try again later."}</div>) :
                                (<div className={styles.circularProgress}>{"Not enough data for the selected month and year to generate a report."}</div>) :
                                (<div className={styles.circularProgress}>{"Select year and month and click ‘Apply’ to generate the report."}</div>)
                            }
                        </div>
                    </div>
                </div>
            </div>

            {inBillingMode && (
                <div className={styles.backdrop}>
                    <div className={styles.confirmatiomBannerDiv}>
                        <div className={styles.confirmationText}>
                            {((billingInfo.length > 0) || (unbillingInfo.length > 0)) ? (
                                selectedTab === 0
                                    ? `Please confirm that you want to bill ${billingInfo.length} of ${maxBillEntries} subjects to CMS`
                                    : `Please confirm that you want to unbill ${unbillingInfo.length} of ${maxUnbillEntries} subjects.`
                            ) : (
                                selectedTab === 0
                                    ? "Please select the subjects you want to confirm billing."
                                    : "Please select the subjects you want to update the billing status for."
                            )}
                        </div>
                        <div className={styles.confirmationButtons}>
                            <div className={(billingInfo.length > 0) || (unbillingInfo.length > 0)?styles.cancelButton:styles.cancelButton2} onClick={() => handleCancelBillingMode()}>
                                Cancel
                            </div>
                            {((billingInfo.length > 0) || (unbillingInfo.length > 0)) && (
                                <div className={styles.submitButton} onClick={(event) => handleUpdateBillingInfo(event)}>
                                    Confirm
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}

        </>
    );
}