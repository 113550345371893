export function getPatientsListSelector({ patientReducer }) {
    if (
        patientReducer &&
        patientReducer.patientsList
    )
        return patientReducer.patientsList;
    else return {};
}

export function getPatientsListLoader({ patientReducer }) {
    if (
        patientReducer &&
        patientReducer.loader
    )
        return true;
    else return false;
}

export function getPatientVitalsListSelector({ patientReducer }) {
    if (
        patientReducer &&
        patientReducer.patientVitalsList
    )
        return patientReducer.patientVitalsList;
    else return {};

}

export function getPatientVitalsListNotesSelector({ patientReducer }) {
    if (
        patientReducer &&
        patientReducer.patientVitalsListNotes
    )
        return patientReducer.patientVitalsListNotes;
    else return "";

}

export function getBaselineSelector({ patientReducer }) {
    if (
        patientReducer &&
        patientReducer.latestBaseline
    )
        return patientReducer.latestBaseline;
    else return {};
}

export function getSubjectVitalsListSelector({ patientReducer, notesReducer }) {
    if (patientReducer && patientReducer.subjectVitalsList) {
        if (notesReducer && notesReducer.patientNotes) {
            patientReducer.subjectVitalsList.forEach(vital => {
                const findNotes = notesReducer.patientNotes.find(note => note.recordId === vital.recordId);
                if (findNotes) {
                    vital.hasNotes = true;
                }
            });
        }
        return patientReducer.subjectVitalsList;
    } else return [];

}

export function getEcgPlotSelector({ patientReducer }) {
    if (
        patientReducer &&
        patientReducer.ecgPlot
    )
        return patientReducer.ecgPlot;
    else return {};
}

export function getRfmPlotSelector({ patientReducer }) {
    if (
        patientReducer &&
        patientReducer.rfmPlot
    )
        return patientReducer.rfmPlot;
    else return {};
}

export function getLfgPlotSelector({ patientReducer }) {
    if (
        patientReducer &&
        patientReducer.lfgPlot
    )
        return patientReducer.lfgPlot;
    else return {};
}

export function getMouthLabLogselector({ patientReducer }) {
    if (
        patientReducer &&
        patientReducer.mouthLabLogs
    )
        return patientReducer.mouthLabLogs;
}

export function getAddSubjectSelector({ patientReducer }) {
    if (
        patientReducer &&
        patientReducer.addSubject
    )
        return patientReducer.addSubject;
    else return {};
}

export function getTimerStatus({ patientReducer }) {
    if (
        patientReducer &&
        patientReducer.timerStatus
    )
        return patientReducer.timerStatus;
    else return false;
}

export function getTimerCount({ patientReducer }) {
    if (
        patientReducer &&
        patientReducer.timerCount
    )
        return patientReducer.timerCount;
    else return 0;
}

export function getTempEdit({ patientReducer }) {
    if (
        patientReducer &&
        patientReducer.tempEdit
    )
        return patientReducer.tempEdit;
    else return false;
}

export function getSbpEdit({ patientReducer }) {
    if (
        patientReducer &&
        patientReducer.sbpEdit
    )
        return patientReducer.sbpEdit;
    else return false;
}
export function getSpo2Edit({ patientReducer }) {
    if (
        patientReducer &&
        patientReducer.spo2Edit
    )
        return patientReducer.spo2Edit;
    else return false;
}

export function getDbpEdit({ patientReducer }) {
    if (
        patientReducer &&
        patientReducer.dbpEdit
    )
        return patientReducer.dbpEdit;
    else return false;
}

export function getPrEdit({ patientReducer }) {
    if (
        patientReducer &&
        patientReducer.prEdit
    )
        return patientReducer.prEdit;
    else return false;
}

export function getHrEdit({ patientReducer }) {
    if (
        patientReducer &&
        patientReducer.hrEdit
    )
        return patientReducer.hrEdit;
    else return false;
}

export function getHrvEdit({ patientReducer }) {
    if (
        patientReducer &&
        patientReducer.hrvEdit
    )
        return patientReducer.hrvEdit;
    else return false;
}

export function getRrEdit({ patientReducer }) {
    if (
        patientReducer &&
        patientReducer.rrEdit
    )
        return patientReducer.rrEdit;
    else return false;
}

export function getFev1Edit({ patientReducer }) {
    if (
        patientReducer &&
        patientReducer.fev1Edit
    )
        return patientReducer.fev1Edit;
    else return false;
}

export function getPefEdit({ patientReducer }) {
    if (
        patientReducer &&
        patientReducer.pefEdit
    )
        return patientReducer.pefEdit;
    else return false;
}

export function getSugarEdit({ patientReducer }) {
    if (
        patientReducer &&
        patientReducer.sugarEdit
    )
        return patientReducer.sugarEdit;
    else return false;
}

export function getPainEdit({ patientReducer }) {
    if (
        patientReducer &&
        patientReducer.painEdit
    )
        return patientReducer.painEdit;
    else return false;
}

export function getWeightEdit({ patientReducer }) {
    if (
        patientReducer &&
        patientReducer.weightEdit
    )
        return patientReducer.weightEdit;
    else return false;
}

export function getAllEdit({ patientReducer }) {
    if (
        patientReducer &&
        patientReducer.allEdit
    )
        return patientReducer.allEdit;
    else return false;
}

export function getDisableAllEditButton({ patientReducer }) {
    if (
        patientReducer &&
        patientReducer.disableAllEditButton
    )
        return patientReducer.disableAllEditButton;
    else return false;
}

export function getSbpPayloadSelector({ patientReducer }) {
    if (patientReducer &&
        patientReducer.sbpPayload
    )
        return patientReducer.sbpPayload;
    else return [];
};

export function getDbpPayloadSelector({ patientReducer }) {
    if (patientReducer &&
        patientReducer.dbpPayload
    )
        return patientReducer.dbpPayload;
    else return [];
};

export function getSpo2PayloadSelector({ patientReducer }) {
    if (patientReducer &&
        patientReducer.spo2Payload
    )
        return patientReducer.spo2Payload;
    else return [];
};

export function getBrPayloadSelector({ patientReducer }) {
    if (patientReducer &&
        patientReducer.brPayload
    )
        return patientReducer.brPayload;
    else return [];
};

export function getHrPayloadSelector({ patientReducer }) {
    if (patientReducer &&
        patientReducer.hrPayload
    )
        return patientReducer.hrPayload;
    else return [];
};

export function getHrvPayloadSelector({ patientReducer }) {
    if (patientReducer &&
        patientReducer.hrvPayload
    )
        return patientReducer.hrvPayload;
    else return [];
};

export function getTempPayloadSelector({ patientReducer }) {
    if (patientReducer &&
        patientReducer.tempPayload
    )
        return patientReducer.tempPayload;
    else return [];
};

export function getPrPayloadSelector({ patientReducer }) {
    if (patientReducer &&
        patientReducer.prPayload
    )
        return patientReducer.prPayload;
    else return [];
};

export function getFevPayloadSelector({ patientReducer }) {
    if (patientReducer &&
        patientReducer.fevPayload
    )
        return patientReducer.fevPayload;
    else return [];
};

export function getPefPayloadSelector({ patientReducer }) {
    if (patientReducer &&
        patientReducer.pefPayload
    )
        return patientReducer.pefPayload;
    else return [];
};

export function getGlucosePayloadSelector({ patientReducer }) {
    if (patientReducer &&
        patientReducer.glucosePayload
    )
        return patientReducer.glucosePayload;
    else return [];
};

export function getWeightPayloadSelector({ patientReducer }) {
    if (patientReducer &&
        patientReducer.weightPayload
    )
        return patientReducer.weightPayload;
    else return [];
};

export function getPainPayloadSelector({ patientReducer }) {
    if (patientReducer &&
        patientReducer.painPayload
    )
        return patientReducer.painPayload;
    else return [];
};

export function getBpComputedSelector({ patientReducer }) {
    if (patientReducer &&
        patientReducer.bpComputed
    )
        return patientReducer.bpComputed;
    else return false;
};

export function getSpo2BrComputedSelector({ patientReducer }) {
    if (patientReducer &&
        patientReducer.spo2BrComputed
    )
        return patientReducer.spo2BrComputed;
    else return false;
};

export function getHrHrvComputedSelector({ patientReducer }) {
    if (patientReducer &&
        patientReducer.hrHrvComputed
    )
        return patientReducer.hrHrvComputed;
    else return false;
};

export function getTempPrComputedSelector({ patientReducer }) {
    if (patientReducer &&
        patientReducer.tempPrComputed
    )
        return patientReducer.tempPrComputed;
    else return false;
};

export function getFevPefComputedSelector({ patientReducer }) {
    if (patientReducer &&
        patientReducer.fevPefComputed
    )
        return patientReducer.fevPefComputed;
    else return false;
};

export function getGwpComputedSelector({ patientReducer }) {
    if (patientReducer &&
        patientReducer.gwpComputed
    )
        return patientReducer.gwpComputed;
    else return false;
};

export function getShowConfirmationBanner({ patientReducer }) {
    if (patientReducer &&
        patientReducer.showConfirmationBanner
    )
        return patientReducer.showConfirmationBanner;
    else return false;
};

export function getFacilityInfoSelector({ patientReducer }) {
    if (patientReducer &&
        patientReducer.facilityInfo
    )
        return patientReducer.facilityInfo;
    else return {};
};

export function getIfEmailAlreadyExistsSelector({ patientReducer }) {
    if (patientReducer &&
        patientReducer.ifEmailAlreadyExists
    )
        return patientReducer.ifEmailAlreadyExists;
    else return false;
};

export function getPatientInfoSelector({ patientReducer }) {
    if (patientReducer &&
        patientReducer.patientInfo
    )
        return patientReducer.patientInfo;
    else return {};
};

export function getGeneralFormEdit({ patientReducer }) {
    if (patientReducer &&
        patientReducer.generalFormEdit
    )
        return patientReducer.generalFormEdit;
    else return false;
};

export function getPayerFormEdit1({ patientReducer }) {
    if (patientReducer &&
        patientReducer.payerFormEdit1
    )
        return patientReducer.payerFormEdit1;
    else return false;
};

export function getPayerFormEdit2({ patientReducer }) {
    if (patientReducer &&
        patientReducer.payerFormEdit2
    )
        return patientReducer.payerFormEdit2;
    else return false;
};

export function getPayerFormEdit3({ patientReducer }) {
    if (patientReducer &&
        patientReducer.payerFormEdit3
    )
        return patientReducer.payerFormEdit3;
    else return false;
};

export function getFamilyFormEdit1({ patientReducer }) {
    if (patientReducer &&
        patientReducer.familyFormEdit1
    )
        return patientReducer.familyFormEdit1;
    else return false;
};

export function getFamilyFormEdit2({ patientReducer }) {
    if (patientReducer &&
        patientReducer.familyFormEdit2
    )
        return patientReducer.familyFormEdit2;
    else return false;
};

export function getFamilyFormEdit3({ patientReducer }) {
    if (patientReducer &&
        patientReducer.familyFormEdit3
    )
        return patientReducer.familyFormEdit3;
    else return false;
};

export function getFacilityFormEdit({ patientReducer }) {
    if (patientReducer &&
        patientReducer.facilityFormEdit
    )
        return patientReducer.facilityFormEdit;
    else return false;
};

export function getCareteamFormEdit1({ patientReducer }) {
    if (patientReducer &&
        patientReducer.careteamFormEdit1
    )
        return patientReducer.careteamFormEdit1;
    else return false;
};

export function getCareteamFormEdit2({ patientReducer }) {
    if (patientReducer &&
        patientReducer.careteamFormEdit2
    )
        return patientReducer.careteamFormEdit2;
    else return false;
};

export function getCareteamFormEdit3({ patientReducer }) {
    if (patientReducer &&
        patientReducer.careteamFormEdit3
    )
        return patientReducer.careteamFormEdit3;
    else return false;
};

export function getPatientDiagnoses({ patientReducer }) {
    if (patientReducer &&
        patientReducer.diagnosesList
    )
        return patientReducer.diagnosesList;
    else return [];
};

export function getFacilityReportSelector({ patientReducer }) {
    if (patientReducer &&
        patientReducer.facilityReport
    )
        return patientReducer.facilityReport;

    else {
        return [];
    };
};

export function getDownloadReportLink({ patientReducer }) {
    if (patientReducer &&
        patientReducer.downloadReportLink
    )
        return patientReducer.downloadReportLink;
    else return '';
};

export function getFacilityBPHSelector({ patientReducer }) {
    if (patientReducer &&
        patientReducer.facilityBPH
    )
        return patientReducer.facilityBPH;
    else return [];
};

export function getFacilityBPHDropdownListSelector({ patientReducer }) {
    if (patientReducer &&
        patientReducer.facilityBPHDropdownList
    )
        return patientReducer.facilityBPHDropdownList;
    else return [];
};

export function getAvailableDiagnosisRankSelector({ patientReducer }) {
    if (patientReducer &&
        patientReducer.availableDiagnosisRanks
    )
        return patientReducer.availableDiagnosisRanks;
    else return [];
};

export function setAdmissionDateSelector({ patientReducer }) {
    if (patientReducer &&
        patientReducer.admissionDate
    )
        return patientReducer.admissionDate;
    else return "";
};

export function getReportStatusSelector({ patientReducer }) {
    if (patientReducer &&
        patientReducer.reportStatus
    )
        return patientReducer.reportStatus;
    else return false;
};

export function getHasValidationError({ patientReducer }) {
    if (patientReducer &&
        patientReducer.hasValidationError
    )
        return patientReducer.hasValidationError;
    else return false;
};