import { patientConstants } from "../Constants/PatientConstants";

const initialState = {
    patientsList: [],
    patientVitalsList: [],
    subjectVitalsList: [],
    ecgPlot: [],
    rfmPlot: [],
    lfgPlot: [],
    loader: false,
    mouthLabLogs: [],
    addSubject: [],
    timerStatus: false,
    timerCount: 0,
    timerStarted: false,
    latestBaseline: [],
    spo2Edit: true,
    hrEdit: true,
    hrvEdit: true,
    sbpEdit: true,
    dbpEdit: true,
    tempEdit: true,
    prEdit: true,
    rrEdit: true,
    fev1Edit: true,
    pefEdit: true,
    sugarEdit: true,
    painEdit: true,
    weightEdit: true,
    allEdit: true,
    disableAllEditButton: false,
    sbpPayload: [],
    dbpPayload: [],
    spo2Payload: [],
    brPayload: [],
    hrPayload: [],
    hrvPayload: [],
    tempPayload: [],
    prPayload: [],
    fevPayload: [],
    pefPayload: [],
    glucosePayload: [],
    weightPayload: [],
    painPayload: [],
    bpComputed: false,
    spo2BrComputed: false,
    hrHrvComputed: false,
    tempPrComputed: false,
    fevPefComputed: false,
    gwpComputed: false,
    showConfirmationBanner: false,
    registerNewPatient: [],
    facilityInfo: {},
    ifEmailAlreadyExists: false,
    patientInfo: {},
    generalFormEdit: false,
    payerFormEdit1: false,
    payerFormEdit2: false,
    payerFormEdit3: false,
    familyFormEdit1: false,
    familyFormEdit2: false,
    familyFormEdit3: false,
    facilityFormEdit: false,
    careteamFormEdit1: false,
    careteamFormEdit2: false,
    careteamFormEdit3: false,
    setPatientStatusRes: false,
    patientVitalsListNotes: [],
    diagnosesList: [],
    facilityReport: [],
    downloadReportLink: '',
    updateFacilityReport: false,
    facilityBPH: [],
    facilityBPHDropdownList: [],
    availableDiagnosisRanks: [],
    admissionDate: "",
    addNewDiagnosis: [],
    updateDiagnosis: [],
    updateGeneralInfo: [],
    updatePayerInfo: [],
    updateFacilityInfo: [],
    updateFamilyInfo: [],
    updateCareteamInfo: [],
    reportStatus: false,
    hasValidationError: false,
};

export function patientReducer(state = initialState, action) {
    switch (action.type) {
        case patientConstants.GET_PATIENTS_LIST:
            return {
                ...state,
            };
        case patientConstants.GET_PATIENTS_LIST_SUCCESS:
            return {
                ...state,
                patientsList: action.payload && action.payload.subjects
            };
        case patientConstants.GET_PATIENTS_LIST_FAILURE:
            return {
                ...state,
                patientsList: []
            };
        case patientConstants.GET_PATIENT_VITALS_LIST:
            return {
                ...state,
                loader: true,
            };
        case patientConstants.GET_PATIENT_VITALS_LIST_SUCCESS:
            return {
                ...state,
                patientVitalsList: action.payload && action.payload.vitals,
                patientVitalsListNotes: action.payload && action.payload.notes && action.payload.notes[0] && action.payload.notes[0].note,
                loader: false
            };
        case patientConstants.GET_PATIENT_VITALS_LIST_FAILURE:
            return {
                ...state,
                loader: false
            };
        case patientConstants.GET_SUBJECT_VITALS_LIST:
            return {
                ...state,
            };
        case patientConstants.GET_SUBJECT_VITALS_LIST_SUCCESS:
            return {
                ...state,
                subjectVitalsList: action.payload && action.payload.vitals
            };
        case patientConstants.GET_SUBJECT_VITALS_LIST_FAILURE:
            return {
                ...state,
            };
        case patientConstants.GET_ECG_PLOT:
            return {
                ...state,
            };
        case patientConstants.GET_ECG_PLOT_SUCCESS:
            return {
                ...state,
                ecgPlot: action.payload && action.payload.vitals
            };
        case patientConstants.GET_ECG_PLOT_FAILURE:
            return {
                ...state,
            };
        case patientConstants.GET_RFM_PLOT:
            return {
                ...state,
            };
        case patientConstants.GET_RFM_PLOT_SUCCESS:
            return {
                ...state,
                rfmPlot: action.payload && action.payload.vitals
            };
        case patientConstants.GET_RFM_PLOT_FAILURE:
            return {
                ...state,
            };
        case patientConstants.GET_LFG_PLOT:
            return {
                ...state,
            };
        case patientConstants.GET_LFG_PLOT_SUCCESS:
            return {
                ...state,
                lfgPlot: action.payload && action.payload.vitals
            };
        case patientConstants.GET_LFG_PLOT_FAILURE:
            return {
                ...state,
            };
        case patientConstants.GET_MOUTHLAB_LOGS:
            return {
                ...state,
            };
        case patientConstants.GET_MOUTHLAB_LOGS_SUCCESS:
            return {
                ...state,
                mouthLabLogs: action.payload && action.payload.devices
            };
        case patientConstants.GET_MOUTHLAB_LOGS_FAILURE:
            return {
                ...state,
            };
        case patientConstants.GET_ADD_SUBJECT:
            return {
                ...state,
            };
        case patientConstants.GET_ADD_SUBJECT_SUCCESS:
            return {
                ...state,
                addSubject: action.payload
            };
        case patientConstants.GET_ADD_SUBJECT_FAILURE:
            return {
                ...state,
            };
        case patientConstants.SET_TIMER_STATUS:
            return {
                ...state,
                timerStatus: action.payload
            };
        case patientConstants.SET_TIMER_COUNT:
            return {
                ...state,
                timerCount: action.payload
            };
        case patientConstants.PUT_START_TIMER:
            return {
                ...state,
            };
        case patientConstants.PUT_START_TIMER_SUCCESS:
            return {
                ...state,
                timerStarted: action.payload && action.payload.success
            };
        case patientConstants.PUT_START_TIMER_FAILURE:
            return {
                ...state,
                timerStarted: false
            };
        case patientConstants.PUT_END_TIMER:
            return {
                ...state,
            };
        case patientConstants.PUT_END_TIMER_SUCCESS:
            return {
                ...state,
                timerStarted: false
            };
        case patientConstants.PUT_END_TIMER_FAILURE:
            return {
                ...state,
                timerStarted: false
            };
        case patientConstants.PUT_ABORT_END_TIMER:
            return {
                ...state,
            };
        case patientConstants.PUT_ABORT_END_TIMER_SUCCESS:
            return {
                ...state,
                timerStarted: false
            };
        case patientConstants.PUT_ABORT_END_TIMER_FAILURE:
            return {
                ...state,
                timerStarted: false
            };
        case patientConstants.GET_LATEST_BASELINE:
            return {
                ...state,
            };
        case patientConstants.GET_LATEST_BASELINE_SUCCESS:
            return {
                ...state,
                latestBaseline: action.payload.baseline
            };
        case patientConstants.GET_LATEST_BASELINE_FAILURE:
            return {
                ...state,
            };
        case patientConstants.GET_SBP_PAYLOAD:
            return {
                ...state,
            };
        case patientConstants.GET_SBP_PAYLOAD_SUCCESS:
            return {
                ...state,
                sbpPayload: action.payload
            };
        case patientConstants.GET_SBP_PAYLOAD_FAILURE:
            return {
                ...state,
                dbpPayload: []
            };
        case patientConstants.GET_DBP_PAYLOAD:
            return {
                ...state,
            };
        case patientConstants.GET_DBP_PAYLOAD_SUCCESS:
            return {
                ...state,
                dbpPayload: action.payload
            };
        case patientConstants.GET_DBP_PAYLOAD_FAILURE:
            return {
                ...state,
                dbpPayload: []
            };
        case patientConstants.GET_SPO2_PAYLOAD:
            return {
                ...state,
            };
        case patientConstants.GET_SPO2_PAYLOAD_SUCCESS:
            return {
                ...state,
                spo2Payload: action.payload
            };
        case patientConstants.GET_SPO2_PAYLOAD_FAILURE:
            return {
                ...state,
                spo2Payload: []
            };
        case patientConstants.GET_BR_PAYLOAD:
            return {
                ...state,
            };
        case patientConstants.GET_BR_PAYLOAD_SUCCESS:
            return {
                ...state,
                brPayload: action.payload
            };
        case patientConstants.GET_BR_PAYLOAD_FAILURE:
            return {
                ...state,
                brPayload: []
            };
        case patientConstants.GET_HR_PAYLOAD:
            return {
                ...state,
            };
        case patientConstants.GET_HR_PAYLOAD_SUCCESS:
            return {
                ...state,
                hrPayload: action.payload
            };
        case patientConstants.GET_HR_PAYLOAD_FAILURE:
            return {
                ...state,
                hrPayload: []
            };
        case patientConstants.GET_HRV_PAYLOAD:
            return {
                ...state,
            };
        case patientConstants.GET_HRV_PAYLOAD_SUCCESS:
            return {
                ...state,
                hrvPayload: action.payload
            };
        case patientConstants.GET_HRV_PAYLOAD_FAILURE:
            return {
                ...state,
                hrvPayload: []
            };
        case patientConstants.GET_TEMP_PAYLOAD:
            return {
                ...state,
            };
        case patientConstants.GET_TEMP_PAYLOAD_SUCCESS:
            return {
                ...state,
                tempPayload: action.payload
            };
        case patientConstants.GET_TEMP_PAYLOAD_FAILURE:
            return {
                ...state,
                tempPayload: []
            };
        case patientConstants.GET_PR_PAYLOAD:
            return {
                ...state,
            };
        case patientConstants.GET_PR_PAYLOAD_SUCCESS:
            return {
                ...state,
                prPayload: action.payload
            };
        case patientConstants.GET_PR_PAYLOAD_FAILURE:
            return {
                ...state,
                prPayload: []
            };
        case patientConstants.GET_FEV_PAYLOAD:
            return {
                ...state,
            };
        case patientConstants.GET_FEV_PAYLOAD_SUCCESS:
            return {
                ...state,
                fevPayload: action.payload
            };
        case patientConstants.GET_FEV_PAYLOAD_FAILURE:
            return {
                ...state,
                fevPayload: []
            };
        case patientConstants.GET_PEF_PAYLOAD:
            return {
                ...state,
            };
        case patientConstants.GET_PEF_PAYLOAD_SUCCESS:
            return {
                ...state,
                pefPayload: action.payload
            };
        case patientConstants.GET_PEF_PAYLOAD_FAILURE:
            return {
                ...state,
                pefPayload: []
            };
        case patientConstants.GET_GLUCOSE_PAYLOAD:
            return {
                ...state,
            };
        case patientConstants.GET_GLUCOSE_PAYLOAD_SUCCESS:
            return {
                ...state,
                glucosePayload: action.payload
            };
        case patientConstants.GET_GLUCOSE_PAYLOAD_FAILURE:
            return {
                ...state,
                glucosePayload: []
            };
        case patientConstants.GET_WEIGHT_PAYLOAD:
            return {
                ...state,
            };
        case patientConstants.GET_WEIGHT_PAYLOAD_SUCCESS:
            return {
                ...state,
                weightPayload: action.payload
            };
        case patientConstants.GET_WEIGHT_PAYLOAD_FAILURE:
            return {
                ...state,
                weightPayload: []
            };
        case patientConstants.GET_PAIN_PAYLOAD:
            return {
                ...state,
            };
        case patientConstants.GET_PAIN_PAYLOAD_SUCCESS:
            return {
                ...state,
                painPayload: action.payload
            };
        case patientConstants.GET_PAIN_PAYLOAD_FAILURE:
            return {
                ...state,
                painPayload: []
            };
        case patientConstants.SET_NEW_THRESHOLD:
            return {
                ...state,
            };
        case patientConstants.SET_NEW_THRESHOLD_SUCCESS:
            return {
                ...state,
            };
        case patientConstants.SET_NEW_THRESHOLD_FAILURE:
            return {
                ...state,
            };
        case patientConstants.SET_TEMP_EDIT:
            return {
                ...state,
                tempEdit: action.payload
            };
        case patientConstants.SET_SBP_EDIT:
            return {
                ...state,
                sbpEdit: action.payload
            };
        case patientConstants.SET_SPO2_EDIT:
            return {
                ...state,
                spo2Edit: action.payload
            };
        case patientConstants.SET_DBP_EDIT:
            return {
                ...state,
                dbpEdit: action.payload
            };
        case patientConstants.SET_HR_EDIT:
            return {
                ...state,
                hrEdit: action.payload
            };
        case patientConstants.SET_HRV_EDIT:
            return {
                ...state,
                hrvEdit: action.payload
            };
        case patientConstants.SET_PR_EDIT:
            return {
                ...state,
                prEdit: action.payload
            };
        case patientConstants.SET_RR_EDIT:
            return {
                ...state,
                rrEdit: action.payload
            };
        case patientConstants.SET_FEV1_EDIT:
            return {
                ...state,
                fev1Edit: action.payload
            };
        case patientConstants.SET_PEF_EDIT:
            return {
                ...state,
                pefEdit: action.payload
            };
        case patientConstants.SET_SUGAR_EDIT:
            return {
                ...state,
                sugarEdit: action.payload
            };
        case patientConstants.SET_PAIN_EDIT:
            return {
                ...state,
                painEdit: action.payload
            };
        case patientConstants.SET_WEIGHT_EDIT:
            return {
                ...state,
                weightEdit: action.payload
            };
        case patientConstants.SET_ALL_EDIT:
            return {
                ...state,
                allEdit: action.payload
            };
        case patientConstants.SET_DISABLE_ALL_EDIT_BUTTON:
            return {
                ...state,
                disableAllEditButton: action.payload
            };
        case patientConstants.SET_BP_COMPUTED:
            return {
                ...state,
                bpComputed: action.payload
            };
        case patientConstants.SET_SPO2_BR_COMPUTED:
            return {
                ...state,
                spo2BrComputed: action.payload
            };
        case patientConstants.SET_HR_HRV_COMPUTED:
            return {
                ...state,
                hrHrvComputed: action.payload
            };
        case patientConstants.SET_TEMP_PR_COMPUTED:
            return {
                ...state,
                tempPrComputed: action.payload
            };
        case patientConstants.SET_FEV_PEF_COMPUTED:
            return {
                ...state,
                fevPefComputed: action.payload
            };
        case patientConstants.SET_GWP_COMPUTED:
            return {
                ...state,
                gwpComputed: action.payload
            };
        case patientConstants.SET_SHOW_CONFIRMATION_BANNER:
            return {
                ...state,
                showConfirmationBanner: action.payload
            };
        case patientConstants.REGISTER_NEW_PATIENT:
            return {
                ...state,
            };
        case patientConstants.REGISTER_NEW_PATIENT_SUCCESS:
            return {
                ...state,
                registerNewPatient: action.payload
            };
        case patientConstants.REGISTER_NEW_PATIENT_FAILURE:
            return {
                ...state,
            };
        case patientConstants.GET_FACILITY_INFO:
            return {
                ...state,
            };
        case patientConstants.GET_FACILITY_INFO_SUCCESS:
            return {
                ...state,
                facilityInfo: action.payload
            };
        case patientConstants.GET_FACILITY_INFO_FAILURE:
            return {
                ...state,
            };
        case patientConstants.GET_IF_EMAIL_ALREADY_EXISTS:
            return {
                ...state,
            };
        case patientConstants.GET_IF_EMAIL_ALREADY_EXISTS_SUCCESS:
            return {
                ...state,
                ifEmailAlreadyExists: action.payload
            };
        case patientConstants.GET_IF_EMAIL_ALREADY_EXISTS_FAILURE:
            return {
                ...state,
            };
        case patientConstants.GET_PATIENT_INFO:
            return {
                ...state,
            };
        case patientConstants.GET_PATIENT_INFO_SUCCESS:
            return {
                ...state,
                patientInfo: action.payload
            };
        case patientConstants.GET_PATIENT_INFO_FAILURE:
            return {
                ...state,
            };
        case patientConstants.SET_GENERAL_FORM_EDIT:
            return {
                ...state,
                generalFormEdit: action.payload
            };
        case patientConstants.SET_PAYER_FORM_EDIT_1:
            return {
                ...state,
                payerFormEdit1: action.payload
            };
        case patientConstants.SET_PAYER_FORM_EDIT_2:
            return {
                ...state,
                payerFormEdit2: action.payload
            };
        case patientConstants.SET_PAYER_FORM_EDIT_3:
            return {
                ...state,
                payerFormEdit3: action.payload
            };
        case patientConstants.SET_FAMILY_FORM_EDIT_1:
            return {
                ...state,
                familyFormEdit1: action.payload
            };
        case patientConstants.SET_FAMILY_FORM_EDIT_2:
            return {
                ...state,
                familyFormEdit2: action.payload
            };
        case patientConstants.SET_FAMILY_FORM_EDIT_3:
            return {
                ...state,
                familyFormEdit3: action.payload
            };
        case patientConstants.SET_FACILITY_FORM_EDIT:
            return {
                ...state,
                facilityFormEdit: action.payload
            };
        case patientConstants.SET_CARETEAM_FORM_EDIT_1:
            return {
                ...state,
                careteamFormEdit1: action.payload
            };
        case patientConstants.SET_CARETEAM_FORM_EDIT_2:
            return {
                ...state,
                careteamFormEdit2: action.payload
            };
        case patientConstants.SET_CARETEAM_FORM_EDIT_3:
            return {
                ...state,
                careteamFormEdit3: action.payload
            };
        case patientConstants.SET_PATIENT_STATUS:
            return {
                ...state,
            };
        case patientConstants.SET_PATIENT_STATUS_SUCCESS:
            return {
                ...state,
                setPatientStatusRes: action.payload
            };
        case patientConstants.SET_PATIENT_STATUS_FAILURE:
            return {
                ...state,
            };
        case patientConstants.GET_DIAGNOSES_PAYLOAD:
            return {
                ...state,
            };
        case patientConstants.GET_DIAGNOSES_PAYLOAD_SUCCESS:
            return {
                ...state,
                diagnosesList: action.payload
            };
        case patientConstants.GET_DIAGNOSES_PAYLOAD_FAILURE:
            return {
                ...state,
                diagnosesList: []
            };
        case patientConstants.GET_FACILITY_REPORT:
            return {
                ...state,
            };
        case patientConstants.GET_FACILITY_REPORT_SUCCESS:
            return {
                ...state,
                facilityReport: action.payload
            };
        case patientConstants.GET_FACILITY_REPORT_FAILURE:
            return {
                ...state,
            };
        case patientConstants.PUT_UPDATE_FACILITY_REPORT:
            return {
                ...state,
            };
        case patientConstants.PUT_UPDATE_FACILITY_REPORT_SUCCESS:
            return {
                ...state,
                updateFacilityReport: action.payload
            };
        case patientConstants.PUT_UPDATE_FACILITY_REPORT_FAILURE:
            return {
                ...state,
            };
        case patientConstants.GET_FACILITY_BPH:
            return {
                ...state,
            };
        case patientConstants.GET_FACILITY_BPH_SUCCESS:
            return {
                ...state,
                facilityBPH: action.payload
            };
        case patientConstants.GET_FACILITY_BPH_FAILURE:
            return {
                ...state,
            };
        case patientConstants.SET_DOWNLOAD_REPORT_LINK:
            return {
                ...state,
                downloadReportLink: action.payload
            };
        case patientConstants.GET_FACILITY_BPH_DROPDOWN_LIST:
            return {
                ...state,
                facilityBPHDropdownList: action.payload
            };
        case patientConstants.GET_AVAILABLE_DIAGNOSIS_RANKS:
            return {
                ...state,
            };
        case patientConstants.GET_AVAILABLE_DIAGNOSIS_RANKS_SUCCESS:
            return {
                ...state,
                availableDiagnosisRanks: action.payload
            };
        case patientConstants.GET_AVAILABLE_DIAGNOSIS_RANKS_FAILURE:
            return {
                ...state,
            };
        case patientConstants.SET_ADMISSION_DATE:
            return {
                ...state,
                admissionDate: action.payload
            };
        case patientConstants.ADD_NEW_DIAGNOSIS:
            return {
                ...state,
            };
        case patientConstants.ADD_NEW_DIAGNOSIS_SUCCESS:
            return {
                ...state,
                addNewDiagnosis: action.payload
            };
        case patientConstants.ADD_NEW_DIAGNOSIS_FAILURE:
            return {
                ...state,
            };
        case patientConstants.UPDATE_DIAGNOSIS:
            return {
                ...state,
            };
        case patientConstants.UPDATE_DIAGNOSIS_SUCCESS:
            return {
                ...state,
                updateDiagnosis: action.payload
            };
        case patientConstants.UPDATE_DIAGNOSIS_FAILURE:
            return {
                ...state,
            };
        case patientConstants.UPDATE_GENERAL_INFO:
            return {
                ...state,
            };
        case patientConstants.UPDATE_GENERAL_INFO_SUCCESS:
            return {
                ...state,
                updateGeneralInfo: action.payload
            };
        case patientConstants.UPDATE_GENERAL_INFO_FAILURE:
            return {
                ...state,
            };
        case patientConstants.UPDATE_PAYER_INFO:
            return {
                ...state,
            };
        case patientConstants.UPDATE_PAYER_INFO_SUCCESS:
            return {
                ...state,
                updatePayerInfo: action.payload
            };
        case patientConstants.UPDATE_PAYER_INFO_FAILURE:
            return {
                ...state,
            };
        case patientConstants.UPDATE_FACILITY_INFO:
            return {
                ...state,
            };
        case patientConstants.UPDATE_FACILITY_INFO_SUCCESS:
            return {
                ...state,
                updateFacilityInfo: action.payload
            };
        case patientConstants.UPDATE_FACILITY_INFO_FAILURE:
            return {
                ...state,
            };
        case patientConstants.UPDATE_FAMILY_INFO:
            return {
                ...state,
            };
        case patientConstants.UPDATE_FAMILY_INFO_SUCCESS:
            return {
                ...state,
                updateFamilyInfo: action.payload
            };
        case patientConstants.UPDATE_FAMILY_INFO_FAILURE:
            return {
                ...state,
            };
        case patientConstants.UPDATE_CARETEAM_INFO:
            return {
                ...state,
            };
        case patientConstants.UPDATE_CARETEAM_INFO_SUCCESS:
            return {
                ...state,
                updateCareteamInfo: action.payload
            };
        case patientConstants.UPDATE_CARETEAM_INFO_FAILURE:
            return {
                ...state,
            };
        case patientConstants.GET_REPORT_STATUS:
            return {
                ...state,
                reportStatus: action.payload
            };
        case patientConstants.SET_VALIDATION_ERROR:
            return {
                ...state,
                hasValidationError: action.payload,
            };
        default:
            return state;
    }
}



